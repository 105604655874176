import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

import Collapsible from 'react-collapsible'

// import imgBlob from '../../images/blob.svg'
import IconChevronRight from '../../images/icons/cheveron-right.svg'
import IconChevronDown from '../../images/icons/cheveron-down.svg'

const SectionHeading = (props) => {
    return (
        <div className="relative cursor-pointer">
            <h2 className="font-heading text-3xl md:pl-6">{props.title}</h2>
            <img alt="icon" className="w-8 absolute top-0 right-0 md:left-0 md:-ml-2  mt-2" src={props.isOpen?IconChevronDown:IconChevronRight} />
        </div>
    )
}


const Information = () => {
    const intl = useIntl()
    const [openSections, setOpenSections] = useState(['hmd']);


    const onOpenSection = (section) =>{
        setOpenSections([...openSections, section])
    }

    const onCloseSection = (section) =>{
        setOpenSections(openSections.filter(v => v !== section))
    }

    const informationQuery = useStaticQuery(graphql`
    query Information {
        allHomeYaml(filter: {section: {eq: "information"}}) {
          nodes {
            information {
              content {
                it
                en
              }
              title {
                it
                en
              }
              slug
            }
          }
        }
      }
    `)

    const informationContent = informationQuery.allHomeYaml.nodes[0].information
    
  
    return (
        
        <div className="grid grid-cols-12 gap-4 mx-auto max-w-screen-xl relative py-20">
  
            <div className="col-span-12 md:col-start-1 md:col-end-7 relative">

            {informationContent.map( informationSection => {

                return(
                    <Collapsible
                        open={openSections.includes(informationSection.slug)}
                        onOpening={()=>onOpenSection(informationSection.slug)}
                        onClosing={()=>onCloseSection(informationSection.slug) }
                        trigger={<SectionHeading title={informationSection.title[intl.locale]} isOpen={openSections.includes(informationSection.slug)}/>}>
                        <p className="text-justify mb-5">
                            {informationSection.content[intl.locale]}
                        </p>
                    </Collapsible>
                )
            }) }
            

{/* 
            <Collapsible
                open={openSections.includes('hmd')}
                onOpening={()=>onOpenSection('hmd')}
                onClosing={()=>onCloseSection('hmd') }
                trigger={<SectionHeading title="Cos'è il Hwal Moo Do" isOpen={openSections.includes('hmd')}/>}>
                 <p className="text-justify mb-5">
                    Hwal Moo Do (hangul: 활 무 도 hanja: 活 武 道) tradotto alla lettera con: <i>"la via che insegna a proteggere la vita"</i>, è un'arte marziale per la difesa personale fondata dal 
                    Grandmaster Kun Hwa Lee. Essa incorpora tecniche di calcio appartenenti alla tradizione coreana e tecniche provenienti da diverse discipline quali: Tae Kwon Do, Tang Soo Do, Karate, Hapkido, Judo, Jujitsu e Kickboxing.
                    La pratica del Hwal Moo Do prevede sia l'allenamento delle tecniche tradizionali che il combattimento nei diversi stili di kickboxing e la difesa personale.
                </p>
            </Collapsible>

            <Collapsible 
                open={openSections.includes('filosofia')}
                onOpening={()=>onOpenSection('filosofia')}
                onClosing={()=>onCloseSection('filosofia') }
                trigger={<SectionHeading title="I nostri insegnamenti" isOpen={openSections.includes('filosofia')}/>}>
                <p className="text-justify mb-5">
                    Alla base del Hwal Moo Do c'è la <i>protezione della vita</i> (Hwal Moo) nel senso più ampio del termine.
                    Questo include non soltanto il saper difendersi contro un'aggressione ma anche capacità di riconoscere ed evitare le situazioni di pericolo, avere uno stile di vita sano,
                    proteggere i propri cari e più in generale dare il buon esempio.
                    </p>
                    
                <p className="text-justify mb-5">
                    Gli insegnamenti del Hwal Moo Do prendono le loro origini da quattro grandi correnti della filosofia orientale:
                    (1) il Confucianesimo nei riguardi della disciplina e della giustizia,
                    (2) Sun Tzu e "L'arte della guerra" in riferimento alla strategia,
                    (3) il Taoismo rispetto al concetto del "wu wei" (unità degli opposti)
                    e (4) il Buddhismo riguardo alle quattro nobili verità e alle tecniche di meditazione.
                    </p >

                <p className="text-justify mb-5">
                    Da un punto di vista pratico il Hwal Moo Do mescola l'arte marziale tradizionale con l'allenamento moderno di Kickboxing e delle tecniche di difesa personale.
                    </p>
            </Collapsible>

            <Collapsible 
                open={openSections.includes('pratica')}
                onOpening={()=>onOpenSection('pratica')}
                onClosing={()=>onCloseSection('pratica') }
                trigger={<SectionHeading title="La pratica" isOpen={openSections.includes('pratica')}/>}>
                
                <p className="text-justify mb-3">
                    Entrare in una sala di pratica di Hwal Moo Do o Korean Dragon significa lasciare fuori le nostre diverse estrazioni sociali, i nostri pensieri politici o i nostri credi religiosi. 
                    Per questo motivo ogni praticante una volta indossata l’uniforme lascia dietro di sé qualsiasi altro effetto personale. 
                    Ogni praticante è inoltre tenuto a mantenere un comportamento educato e rispettoso del proprio Maestro e dei propri compagni di pratica. 
                    La lezione inizia e termina infatti con il saluto al proprio Maestro che darà il buon esempio anche agli studenti più giovani. Le lezioni prevedono allenamento atletico e tecnico sia in ambito tradizionale che nel combattimento kickboxing.
                </p>
                <p className="text-justify mb-3">  
                    Hwal Moo Do e Korean Dragon rappresentano due aspetti del mondo HMD Italia. Due percorsi diversi, ma paralleli che vedono da una parte un più ampio avvicinamento alla pratica marziale (Hwal Moo Do)
                    e dall’altra un allenamento e una crescita più vicini alla pratica di uno sport da combattimento (Korean Dragon).

                </p>
                   
            </Collapsible> */}

            </div>
            {/* style={{background: `url(${imgBlob}) no-repeat`}} > */}
            <div className="col-span-12 md:col-start-8 md:col-end-12 h-full text-center pb-20 md:py-5 bg-local"> 
               
                <div className="flex flex-wrap justify-center">
                    <div className=" px-4">
                        <img className="shadow rounded max-w-full h-auto align-middle border-none" alt="Grandmaster Kun Hwa Lee" src="https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fgrandmaster_lee_kun_hwa.jpg?alt=media&token=30e19264-b1e9-4537-b3b5-df9d87abf68f" />
                        <span className="text-sm text-gray-700">Grandmaster <span className="font-bold">Kun Hwa Lee</span> fondatore <br />  del Hwal Moo Do.</span>
                    </div>
                </div>
               
            </div>
           
        </div> 
    )
}

export default Information;